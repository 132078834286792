<template>
  <ReflectModal
    :title-message="
      $t('legalReports.egaproIndex.setupTable.configurationModal.title')
    "
    :action-message="
      $t(
        'legalReports.egaproIndex.setupTable.configurationModal.saveConfiguration'
      )
    "
    :cancel-message="
      $t('legalReports.egaproIndex.setupTable.configurationModal.cancel')
    "
    action-button-theme="primary"
    @action="updateEgaproIndexSalaryRaisePeriodPreference"
    @cancel="closeDimensionCategoryConfigurationModal"
  >
    <div class="modal__dimension">
      <p class="dimension__label">
        {{ $t(`sharedAnalytics.memberSelection.member.DIMENSION`) }}
      </p>
      <p class="dimension__value">
        {{ $t('legalReports.egaproIndex.setupTable.categories.salaryRaise') }}
      </p>
    </div>
    <div class="modal__table">
      <table class="table">
        <thead class="table__header">
          <tr class="header">
            <th class="header__cell">
              {{
                $t(
                  'legalReports.egaproIndex.setupTable.configurationModal.table.title'
                )
              }}
            </th>
            <th class="header__cell">
              {{
                $t(
                  'legalReports.egaproIndex.setupTable.configurationModal.table.description'
                )
              }}
            </th>
            <th class="header__cell" />
          </tr>
        </thead>
        <tbody class="table__body">
          <tr class="body">
            <td class="body__cell">
              {{
                $t(
                  'legalReports.egaproIndex.setupTable.configurationModal.table.referencePeriod'
                )
              }}
            </td>
            <td class="body__cell body__cell--description">
              {{
                $t(
                  'legalReports.egaproIndex.setupTable.configurationModal.table.referencePeriodDescription'
                )
              }}
            </td>
            <td class="body__cell">
              <ReflectSelectField
                :options="
                  SALARY_RAISE_PERIOD_OPTIONS.map(option => ({
                    label: $t(option.label),
                    value: option.value
                  }))
                "
                :close-on-select="true"
                :size="SelectFieldSize.SMALL"
                track-by="label"
                :value="selectedSalaryRaisePeriodPreference"
                :placeholder="
                  $t(
                    'legalReports.egaproIndex.setupTable.configurationModal.periodPlaceholder'
                  )
                "
                :has-labeled-options="true"
                @input="setSelectedSalaryRaisePeriod"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ReflectModal>
</template>

<script lang="ts">
import { SALARY_RAISE_PERIOD_OPTIONS } from '~/constants/egaproIndex'
import {
  type EgaproIndex,
  type EgaproIndexWithPreviousYearScore,
  type SalaryRaisePeriodPreferenceOption
} from '~/types/egaproIndex'
import { UPDATE_EGAPRO_INDEX_SALARY_RAISE_PERIOD_PREFERENCE_MUTATION } from '~/gql/legalReports/egaproIndex/updateEgaproIndexSalaryRaisePeriodPreference'
import { SelectFieldSize } from '~/components/Reflect/SelectField.vue'

export default {
  name: 'EgaproSalaryRaisePeriodPreferenceModal',
  props: {
    egaproIndex: {
      type: Object as PropType<EgaproIndexWithPreviousYearScore>,
      required: true
    }
  },
  emits: [
    'close-salary-raise-period-preference-modal',
    'update-salary-raise-period-preference'
  ],
  setup() {
    return {
      SALARY_RAISE_PERIOD_OPTIONS,
      SelectFieldSize
    }
  },
  data(): {
    selectedSalaryRaisePeriodPreference: SalaryRaisePeriodPreferenceOption | null
  } {
    return {
      selectedSalaryRaisePeriodPreference:
        this.egaproIndex.smallCompanySalaryRaisePeriodPreference !== null
          ? SALARY_RAISE_PERIOD_OPTIONS.map(option => ({
              label: this.$t(option.label),
              value: option.value
            })).find(
              option =>
                option.value ===
                this.egaproIndex.smallCompanySalaryRaisePeriodPreference
            )!
          : null
    }
  },

  methods: {
    closeDimensionCategoryConfigurationModal() {
      this.$emit('close-salary-raise-period-preference-modal')
    },
    setSelectedSalaryRaisePeriod(
      salaryRaisePeriodPreference: SalaryRaisePeriodPreferenceOption
    ) {
      this.selectedSalaryRaisePeriodPreference = salaryRaisePeriodPreference
    },
    async updateEgaproIndexSalaryRaisePeriodPreference() {
      if (
        this.egaproIndex.smallCompanySalaryRaisePeriodPreference !==
        this.selectedSalaryRaisePeriodPreference!.value
      ) {
        const updateEgaproIndexSalaryRaisePeriodPreference = (
          await this.$apiGqlClient.mutate({
            mutation:
              UPDATE_EGAPRO_INDEX_SALARY_RAISE_PERIOD_PREFERENCE_MUTATION,
            variables: {
              id: this.egaproIndex.id,
              salaryRaisePeriodPreference:
                this.selectedSalaryRaisePeriodPreference!.value
            }
          })
        ).data.updateEgaproIndexSalaryRaisePeriodPreference as EgaproIndex

        this.$emit(
          'update-salary-raise-period-preference',
          updateEgaproIndexSalaryRaisePeriodPreference.smallCompanySalaryRaisePeriodPreference
        )
      }

      this.closeDimensionCategoryConfigurationModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__dimension {
    margin-bottom: $margin-regular;

    .dimension {
      &__label {
        @include font-size($font-size-mini);
        @include font-text($font-weight-book);
        margin-bottom: 4px;
      }

      &__value {
        @include font-size($font-size-regular);
        @include font-text($font-weight-book);
        padding: $margin-mini $margin-small;
        border-radius: 8px;
        background-color: $bg-primary;
        color: $text-primary;
        width: 144px;
      }
    }
  }

  &__table {
    border-radius: 8px;
    border: 1px solid rgba($border-secondary, 0.6);

    .table {
      display: table;
      table-layout: auto;
      width: 100%;

      &__header {
        .header {
          &__cell {
            @include font-size($font-size-regular);
            @include font-text($font-weight-book);
            text-align: left;
            padding: $margin-mini;
            background-color: $bg-quinary;
          }

          &__cell:first-of-type {
            border-top-left-radius: 8px;
          }

          &__cell:last-of-type {
            border-top-right-radius: 8px;
          }
        }
      }

      &__body {
        .body {
          &__cell {
            @include font-size($font-size-small);
            @include font-text($font-weight-book);
            vertical-align: middle;
            padding: $margin-mini;

            &--description {
              color: $text-tertiary;
              white-space: pre-line;
            }
          }
        }
      }
    }
  }
}
</style>
