import { gql } from 'graphql-tag'

const EGAPRO_INDEX_FIELDS = `
  id
  year
  startDate
  endDate
  referencePeriodLastUpdatedAt
  hasCustomizedCategorization
  smallCompanySalaryRaisePeriodPreference
  smallCompanySalaryRaisePeriodPreferenceLastUpdatedAt
  isComputed
  subjectWorkforce
  workforceInCalculation
  totalScore
  genderPayGapScore
  largeCompanyGenderPayRaiseGapScore
  smallCompanyGenderPayRaiseGapScore
  largeCompanyGenderPromotionGapScore
  payRaiseAfterMaternityLeaveScore
  topHighestSalariesScore
`

export const EGAPRO_INDEX_FRAGMENT = gql`
  fragment EgaproIndexFields on EgaproIndex {
    ${EGAPRO_INDEX_FIELDS}
  }
`

export const EGAPRO_INDEX_WITH_PREVIOUS_YEAR_SCORE_DTO_FRAGMENT = gql`
  fragment EgaproIndexWithPreviousYearScoreDtoFields on EgaproIndexWithPreviousYearScoreDto {
    ${EGAPRO_INDEX_FIELDS}
    previousYearTotalScore
  }
`
