import { gql } from 'graphql-tag'
import { EGAPRO_INDEX_FRAGMENT } from './egaproIndexFragment'

export const UPDATE_EGAPRO_INDEX_SALARY_RAISE_PERIOD_PREFERENCE_MUTATION = gql`
  ${EGAPRO_INDEX_FRAGMENT}
  mutation UPDATE_EGAPRO_INDEX_SALARY_RAISE_PERIOD_PREFERENCE(
    $id: String!
    $salaryRaisePeriodPreference: Int!
  ) {
    updateEgaproIndexSalaryRaisePeriodPreference(
      id: $id
      salaryRaisePeriodPreference: $salaryRaisePeriodPreference
    ) {
      ...EgaproIndexFields
    }
  }
`
